<template>
  <div class="q-pa-md">
    <div class="fit column wrap items-start content-center">
      <div class="text-h5 text-grey-7 self-center text-weight-bold">
        {{ $t("common.signin") }}
      </div>

      <div class="q-pt-md" />

      <q-card
        flat
        bordered
        class="login-card q-py-lg q-mx-auto"
        align="center"
        style="width: 60em"
      >
        <!-- https://developers.google.com/identity/branding-guidelines -->
        <q-card-section>
          <div class="login-buttons">
            <div class="q-pb-md" style="cursor: pointer">
              <q-img
                no-spinner
                :src="googleButtonSrcLink"
                no-transition
                width="15em"
                @click="login"
                @mouseover="googleButtonMouseOver = true"
                @mouseleave="googleButtonMouseOver = false"
              />
            </div>
          </div>
        </q-card-section>

        <SeparatorWithText :text="$t('views.signin.separator')" />

        <q-card-section class="column">
          <email-login />
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { AuthModule } from "@/store/modules/auth";
import { useRouter } from "vue-router";
import EmailLogin from "@/components/auth/EmailLogin.vue";
import SeparatorWithText from "@/components/SeparatorWithText.vue";
import {
  AuthCodeFlowErrorResponse,
  AuthCodeFlowSuccessResponse,
  useTokenClient,
} from "vue3-google-signin";

export default defineComponent({
  name: "LoginView",

  components: { SeparatorWithText, EmailLogin },

  setup() {
    const $router = useRouter();
    const loading = ref(false);
    const googleButtonMouseOver = ref(false);
    const googleButtonSrcLink = ref(
      "btn_google_signin_light_normal_web@2x.png",
    );

    const isEmailLoginClicked = ref(false);

    function updateGoogleButtonSrcLink() {
      if (AuthModule.isDarkMode) {
        if (googleButtonMouseOver.value) {
          googleButtonSrcLink.value =
            "btn_google_signin_dark_pressed_web@2x.png";
        } else {
          googleButtonSrcLink.value =
            "btn_google_signin_dark_normal_web@2x.png";
        }
      } else {
        if (googleButtonMouseOver.value) {
          googleButtonSrcLink.value =
            "btn_google_signin_light_pressed_web@2x.png";
        } else {
          googleButtonSrcLink.value =
            "btn_google_signin_light_normal_web@2x.png";
        }
      }
    }

    updateGoogleButtonSrcLink();

    // Watcher can only detect getter change
    watch([AuthModule.getDarkMode, googleButtonMouseOver], () => {
      updateGoogleButtonSrcLink();
    });

    const handleOnLoginSuccess = (response: AuthCodeFlowSuccessResponse) => {
      const { access_token } = response;
      AuthModule.obtainTokenWithGoogle({ access_token })
        .then((result) => {
          console.log("Login successful: ", result);
          AuthModule.postLogin()
            .then(() => {
              $router.push({ name: "Course", replace: true });
            })
            .catch((e) => {
              return Promise.reject(e);
            });
        })
        .catch((error) => {
          console.error("Login failed: ", error);
        });
    };

    const handleOnLoginError = (errorResponse: AuthCodeFlowErrorResponse) => {
      console.log("Login Error: ", errorResponse);
    };

    const { login } = useTokenClient({
      onSuccess: handleOnLoginSuccess,
      onError: handleOnLoginError,
    });

    return {
      loading,
      AuthModule,
      googleButtonMouseOver,
      googleButtonSrcLink,
      isEmailLoginClicked,
      login,
    };
  },
});
</script>
