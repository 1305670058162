<template>
  <div class="q-pa-md row justify-center full-height full-width">
    <q-card flat bordered class="q-pt-none">
      <q-card-section>
        <p>
          {{ $t("views.about.description") }}
          <a href="https://www.ilect.net/">iLect</a>.
        </p>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="q-pt-none">
        <h6 id="what-will-we-do-with-user-data">
          {{ $t("views.about.disclaimer") }}
        </h6>

        <p>{{ $t("views.about.disclaimer2") }}</p>
        <p>
          {{ $t("views.about.privacy") }}:
          <router-link to="privacy-policy"> iLect-CL </router-link>
        </p>
      </q-card-section>
    </q-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AboutView",
});
</script>
