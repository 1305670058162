import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios, { InternalAxiosRequestConfig } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";

import GoogleSignInPlugin from "vue3-google-signin";

import { Quasar } from "quasar";
import iconSet from "quasar/icon-set/fontawesome-v5";
import quasarUserOptions from "./quasar-user-options";

import { createPinia } from "pinia";

import { AuthModule } from "@/store/modules/auth";
import { i18n } from "./boot/i18n";
import "quasar/dist/quasar.sass";

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

// ID below is for the project 'ilect-colab-dev'.
const gAuthClientId =
  import.meta.env.VITE_APP_GAUTH_CLIENT_ID ||
  "996341021183-k01kp4tm4mhh0onbhr2d9g5umhcnkevj.apps.googleusercontent.com";

// Time until
const SECONDSLEFTTOUPDATETOKEN = 3600;

app.use(i18n);
app.use(Quasar, quasarUserOptions);
app.use(router);
app.use(GoogleSignInPlugin, {
  clientId: gAuthClientId,
});
app.use(Quasar, {
  iconSet: iconSet,
});

axios.defaults.baseURL = import.meta.env.VITE_APP_BACKEND_API_ENDPOINT;

axios.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
  const token = AuthModule.authToken;
  let expiry = null;
  if (token && token !== "null") {
    const decoded = jwtDecode<JwtPayload>(token);
    expiry = decoded.exp;
  }
  // expiry = 1720673062 // for debug set this manually
  // Attempt to refresh token when it about to expire (1 hour before expiry check SECONDSLEFTTOUPDATETOKEN)
  // Values are in seconds since epoch

  const currentTime = new Date().valueOf() / 1000;
  // Also check if we are in interceptor for the below request.
  // We don't want a infinite loop of requests.

  if (expiry && expiry - currentTime <= SECONDSLEFTTOUPDATETOKEN) {
    const RefreshToken = async () => {
      await AuthModule.refreshCurrentToken()
        .then(() => {
          request.headers.Authorization = `Bearer ${AuthModule.authToken}`;
          return request;
        })
        .catch(() => {
          AuthModule.resetToken();

          // Redirect to the login page.
          if (window.location.pathname !== "/login") {
            window.location.href = "/login";
          }
        });
    };
    await RefreshToken();
  }

  if (AuthModule.isAuthenticated) {
    request.headers["Authorization"] = `Bearer ${AuthModule.authToken}`;
    request.headers["Content-Type"] = "application/json;charset=utf-8";
  }
  return request;
});

// This is for authentication error. Usually, pages for authenticated users
// cannot be seen by guest users, but it is possible that authenticated users
// lost their validated tokens for some reason.
let isRefreshing = false;

axios.interceptors.response.use(undefined, (error) => {
  const originalRequest = error.config;

  if (error.response.status == 401) {
    if (isRefreshing) {
      isRefreshing = false;
      return Promise.reject(error);
    }

    isRefreshing = true;
    if (AuthModule.isAuthenticated) {
      return AuthModule.refreshCurrentToken()
        .then(() => {
          originalRequest.headers["Authorization"] =
            `Bearer ${AuthModule.authToken}`;
          return axios(originalRequest);
        })
        .catch(() => {
          AuthModule.resetToken();

          // Redirect to the login page.
          if (window.location.pathname != "/login") {
            window.location.href = "/login";
          }

          return Promise.reject(error);
        });
    }
  }
  return Promise.reject(error);
});

app.mount("#app");
