import {
  Configuration,
  ConfigurationParameters,
} from "@/openapi-ts/configuration";
import {
  AssignmentApiFactory,
  QuizApiFactory,
  AssessmentAnalysisApiFactory,
  AuthApiFactory,
  CourseApiFactory,
  DatasetApiFactory,
  DatasetGroupApiFactory,
  EditorListApiFactory,
  EvaluationApiFactory,
  EventApiFactory,
  ForumApiFactory,
  LeaderboardApiFactory,
  ScoreApiFactory,
  StudentListApiFactory,
  SubmissionApiFactory,
  QuizSubmissionApiFactory,
  TestCaseApiFactory,
  TestTypeApiFactory,
  WorkbookConfigApiFactory,
  VimeoVideosApiFactory,
} from "@/openapi-ts";
import { DefaultApiFactory } from "@/openapi-workbook-builder-ts";
import axios from "axios";

// axios interceptor will take care of jwt
const axiosClient = axios;

// Django main backend apis
const ilectBackendConfigurationParameter: ConfigurationParameters = {
  basePath: import.meta.env.VITE_APP_BACKEND_API_ENDPOINT,
};
const ilectBackendConfiguration = new Configuration(
  ilectBackendConfigurationParameter,
);

export const authApi = AuthApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const assignmentApi = AssignmentApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const quizApi = QuizApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const courseApi = CourseApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const datasetApiFactory = DatasetApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const datasetGroupApi = DatasetGroupApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const editorListApi = EditorListApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const evaluationApi = EvaluationApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const eventApi = EventApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const forumApi = ForumApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const leaderboardApi = LeaderboardApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const scoreApi = ScoreApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const studentListApi = StudentListApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const submissionApi = SubmissionApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const quizSubmissionApi = QuizSubmissionApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const testCaseApi = TestCaseApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const testTypeApi = TestTypeApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const workbookConfigApi = WorkbookConfigApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const assessmentAnalysisApi = AssessmentAnalysisApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);
export const VimeoVideosApi = VimeoVideosApiFactory(
  ilectBackendConfiguration,
  undefined,
  axiosClient,
);

// Workbook Builder apis
const ilectBuilderConfigurationParameter: ConfigurationParameters = {
  basePath: import.meta.env.VITE_APP_BUILDER_API_ENDPOINT,
};
const ilectBuilderConfiguration = new Configuration(
  ilectBuilderConfigurationParameter,
);

export const workbookApi = DefaultApiFactory(
  ilectBuilderConfiguration,
  undefined,
  axiosClient,
);
